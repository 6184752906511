@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@font-face {
  font-family: "Century Gothic";
  src: url("./assets/fonts/CenturyGothic.ttf");
}


@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .search-container-shadow {
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.12);
  }
}

* {
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none !important;
}
